import React from 'react';
import Navbar from '../components/Navbar';
import ReachUs from '../components/ReachUs';
import Footer from '../components/Footer';
import contact from '../assets/contact.jpg';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

function Contact() {
  const containerStyle = {
    backgroundImage: `url(${contact})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  };
  const mapStyles = {
    height: "400px",
    width: "100%"
  };
    const defaultCenter = {
    lat: 40.712776,
    lng: -74.005974
  };

  return (
    <div>
      <div style={containerStyle}>
        <Navbar />
        <div className="py-40 md:py-60 lg:py-80 text-center">
          <p className="text-lg md:text-xl">SEBORE INTERNATIONAL FARMS</p>
          <h1 className="font-bold text-4xl md:text-6xl lg:text-8xl">CONTACT US</h1>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto px-10 md:px-20 lg:px-40 py-10 text-black">
          <LoadScript
      googleMapsApiKey="AIzaSyDlMzYvuVPUzDuYLD9lVzwoVgYwxe6deDw"
    >
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={10}
        center={defaultCenter}
      >
        {/* Child components, such as markers, can be added here */}
      </GoogleMap>
    </LoadScript>
      </div>

      <div className="max-w-[1240px] mx-auto px-10 md:px-8 lg:px-16 py-10 text-black text-center">
        <h1 className="text-2xl font-bold">Send Message</h1>
        <form className="flex flex-col justify-center items-center mx-auto py-8">
          <div className="grid grid-cols-1 font-serif md:grid-cols-2 gap-4">
            <input
              className="px-4  outline-none border-b border-[#C3BDBD] focus:border-gray-400 mt-4"
              placeholder="Type your name.."
              name="name"
              required
            />
            <input
              className="mx-3 outline-none border-b border-[#C3BDBD] focus:border-gray-400 mt-4"
              placeholder="Type your email..."
              name="email"
              required
            />
          </div>
          <label className="text-[#C3BDBD] my-4">Comment</label>
          <textarea
            cols="10"
            rows="10"
            placeholder="Type something"
            className="border font-serif rounded w-full md:w-[300px] h-[150px] border-[#C3BDBD] focus:border-gray-400 outline-none p-2"
          ></textarea>
          <button
            type="submit"
            className="border  font-serif border-[#ffba08] text-black font-medium w-[100px] ml-2 my-4 py-2"
          >
            SEND
          </button>
        </form>
      </div>

      <ReachUs />
      <Footer />
    </div>
  );
}

export default Contact;
