import React from "react";
import { Link } from 'react-router-dom';
import sebore from '../assets/sebore.png'

function Footer() {
     const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling
    });
  };
    return (
        <>
            <div className="h-full w-full flex  px-4 md:px-10  flex-col md:flex-row justify-around items-center p-10 text-black">
                <div className="py-10 md:py-20">
                    <Link to="/" className="mt-16 " onClick={scrollToTop}>
                       <img className="w-[80px] md:w-[400px] lg:w-[200px] mx-auto my-4" src={sebore} alt="/" />
                    </Link>
                    <h1 className="text-gray-800 font-semibold">
                        © 2024 SIFL. All rights reserved..
                    </h1>
                </div>

                <div className="p-5 font-bold  px-4 md:px-10 py-10">
                    <ul>
                        <p className="text-xl md:text-2xl pb-4 md:pb-8">ADDITIONAL LINKS</p>
                           <Link to="/" className="mt-16 " onClick={scrollToTop}>
                        <li className="text-md font-serif md:text-lg pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            HOME
                        </li>
                        </Link>
                         <Link to="/ourfarm" className="mt-16 " onClick={scrollToTop}>
                        <li className="text-md font-serif md:text-lg pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            ABOUT US
                        </li>
                        </Link>
                         <Link to="/communitydev" className="mt-16 " onClick={scrollToTop}>
                        <li className="text-md font-serif md:text-lg pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            COMMUNITY DEVELOPMENT
                        </li>
                        </Link>
                         <Link to="/investors" className="mt-16 " onClick={scrollToTop}>
                        <li className="text-md font-serif md:text-lg pb-2 font-semibold hover:text-blue-600 cursor-pointer">
                            INVESTORS
                        </li>
                        </Link>
                       
                    </ul>
                </div>

                <div className="py-10 px-4 md:px-10">
                    <ul>
                        <p className="font-bold text-xl md:text-2xl pb-4 md:pb-8">GET A NEWSLETTER</p>
                        <li className="text-md md:text-lg pb-2 font-serif font-semibold hover:text-blue-600 cursor-pointer">
                            <input
                                className='mx-3 outline-none font-serif border-b border-[#C3BDBD] focus:border-gray-400 mt-4'
                                placeholder='E-mail'
                                name='email'
                                required
                            />
                            <button
                                type="submit"
                                className='border font-serif border-[#ffba08] text-black font-medium w-[100px] ml-2 my-4 py-2'
                            >
                                SUBSCRIBE
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Footer;
