import React from 'react';
import Navbar from '../components/Navbar';
import sebore from '../assets/sebore.png';
import diary from '../assets/Dairy.jpg';
import ReachUs from '../components/ReachUs';
import Footer from '../components/Footer';
import Partners from '../components/Partners';
import Timeline from '../components/Timeline';


function About() {
  const containerStyle = {
    backgroundImage: `url(${diary})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh', // Adjusted height for responsiveness
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  };

  return (
    <div>
      <div style={containerStyle}>
        <Navbar />
        <div className="py-60 md:py-80 lg:py-80 xl:py-90">
          <h1 className="font-bold text-4xl md:text-6xl font-sans lg:text-8xl text-center md:px-8 lg:px-20">
            LEADING DAIRY AND RENEWABLE ENERGY COMPANY IN NIGERIA
          </h1>
        </div>
      </div>

      <div className="items-center py-8 md:py-16 md:px-8 lg:px-20">
        <p className="py-2  font-serif px-10 md:px-10 lg:px-40 text-lg md:text-2xl lg:text-2xl text-justify">
          Sebore International Farms Ltd.(SIFL) is a Nigerian registered integrated sector market
          leader specialised in dairy industry, eco-energy, and urban-rural industrialization.
        </p>
      </div>

      <div className="py-5 px-4 md:px-10 lg:px-40 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 text-black text-justify">
        <div className="font-bold text-4xl md:text-6xl lg:text-7xl px-4 md:px-20 py-8 lg:py-10">
          <img className="w-full h-auto max-w-md lg:w-[200px] mx-auto mb-4 p-6" src={sebore} alt="Profile" />
        </div>
        <div className="text-lg md:text-xl px-10 md:px-20">
          <p className="font-bold text-2xl mb-4">What We Do</p>
          <p className="mb-4 font-serif">
            At SIFL, we leverage technology and relationships with dairy farmers in the host community
            to provide consumers with milk and dairy products manufactured using high-quality raw milk
            in Adamawa's magnificent natural environment.
          </p>
          <p className="mb-4  font-serif">
            Even beyond agriculture, we believe in a future where energy is consistently available
            for all as it is crucial in human development; this belief fuels our path in Renewable
            Energy Technologies (RETs), the future of energy sources.
          </p>
          <p className="mb-4 font-serif">
            As an Integrated Renewable Energy Company, our independent power production makes
            electric power available through sustainable innovation to benefit rural and even urban
            communities, thereby creating opportunities for the rural communities to thrive.
          </p>
        </div>
      </div>
       <p className="mb-4 font-serif text-lg md:text-xl px-10 md:px-20 text-justify">
            Also, we are a licensed active free zone under the Nigeria Export Processing Zones Authority (NEPZA). Our goal as an Export Processing Zone is to contribute to social-economic development by boosting national export growth and diversification, creation of jobs and income, and promoting technology and skill transfers.
          </p>
      <Timeline/>
      <Partners />
      <ReachUs />
      <Footer />
    </div>
  );
}

export default About;
