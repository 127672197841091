import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { Link } from 'react-router-dom';


function ReachUs() {
  return (
    <div>
      <div className='px-10 md:px-20 lg:px-40 xl:px-60 grid grid-cols-1 text-justify lg:grid-cols-2 gap-8 lg:gap-0 text-white'>
        <div className='bg-[#136f63]'>
          <h1 className='font-bold text-2xl py-6 md:py-10 lg:py-12 text-center px-6 md:px-10 lg:px-20'>
            CONTACT
          </h1>
          <p className='px-10 md:px-20 lg:px-20 flex items-center font-serif'>
        <FaLocationDot size={40} style={{ marginRight: '10px' }} /> 
        Sebore International Farms Limited, KM 12. Mayo Belwa – Ngurore Road,
        Adamawa State, Nigeria.
      </p>
         <p className='px-10 md:px-20 lg:px-20 font-serif py-4 flex items-center'>
        <FaPhoneVolume size={30} style={{ marginRight: '10px' }} /> 
        +2347031151150
      </p>
      <p className='px-10 md:px-20font-serif lg:px-20 py-4 flex items-center'>
        <IoIosMail size={40} style={{ marginRight: '10px' }} /> 
        admin@seboregroup.com
      </p>
    <div className="flex px-20 items-center mb-4">
      <Link className='mr-4'><FaInstagram /></Link>
      <Link className='mr-4'><FaXTwitter /></Link>
      <Link><FaFacebookF /></Link>
    </div>
        </div>
        <div className='bg-[#59656f]'>
          <div>
            <h1 className='font-bold text-2xl py-6 md:py-10 lg:py-12 px-6 items-center text-center md:px-10 lg:px-20'>
              Get in Touch Let's Talk
            </h1>
            <form className='flex flex-col font-serif justify-center items-center mx-auto '>
              <input
                className='px-6 md:px-10 lg:px-20 outline-none border-b border-[#C3BDBD] bg-[#59656f] focus:border-gray-400 mt-4'
                placeholder='Type your name..'
                name='name'
                required
              />

              <input
                className='px-6 md:px-10 lg:px-20 outline-none border-b border-[#C3BDBD] bg-[#59656f] focus:border-gray-400 mt-4'
                placeholder='Type your email...'
                name='email'
                required
              />
              <textarea
                cols='30'
                rows='10'
                placeholder='Type something'
                className='resize-none border h-[150px] border-[#C3BDBD] mt-4 bg-[#59656f] focus:border-gray-400 outline-none p-2'
              ></textarea>
              <button
                type='submit'
                className='border border-[#C3BDBD] text-black font-medium w-[200px] ml-2 my-8 py-2'
              >
                SEND A MESSAGE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReachUs;
