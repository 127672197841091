import React from 'react'
import woman from '../assets/woman.JPG';
import Navbar from '../components/Navbar';
import Community from '../components/Community';
import Footer from '../components/Footer';



function CommunityDev() {
  const cdstyle = {
    backgroundImage: `url(${woman})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  };
  return (
    <div>
      <div style={cdstyle}>
        <Navbar />
        <div className="py-40 md:py-40 lg:py-80 text-center">
          <p className="text-2xl md:text-4xl font-bold px-10">A BETTER COMMUNITY MAKES A BETTER WORLD</p>
        </div>
      </div>
       <div className="items-center py-8 md:py-16 md:px-8 lg:px-20">
        <p className="py-2 font-serif px-10 md:px-10 lg:px-40 text-lg md:text-2xl lg:text-2xl text-justify">
          We are building stable and prosperous communities that help feed the nation.
        </p>
      </div>
      <div className="items-center md:px-8 lg:px-20">
        <p className=" font-bold py-2 px-10 md:px-10 lg:px-40 text-2xl md:text-4xl lg:text-4xl text-justify">
         Our Strategy
        </p>
        <p className="px-10 font-serif md:px-10  py-2 md:py-4 lg:py-6 lg:px-40  items-center text-md md:text-md lg:text-md text-justify">
         We believe giving back to the communities where we carry out business is an integral part of our overall strategy. Our CSR initiatives are focused on investing in the communities in 
which we operate and are implemented through strategic projects designed to maximise impact and ensure positive, long-term sustainability. 
        </p>
  <p className="px-10 font-serif md:px-10  py-2 md:py-4 lg:py-6 lg:px-40  items-center text-md md:text-md lg:text-md text-justify">
        To achieve this, we periodically carry out need-gap analysis in target communities to be 
able to accurately determine areas where our support is most needed. This enables us to deliver CSR projects that are relevant and impactful. In line with the principles of the 
United Nations Sustainable Development Goals. 
</p>
<p className="px-10 font-serif  lg:px-40  items-center text-md md:text-md lg:text-md text-justify">
   Sebore Farms is committed to the improvement of the socioeconomic condition of the 
communities where we do business. Our social initiatives are geared towards eradicating extreme poverty, women empowerment encouraging skills development and capacity 
building, employment creation.
</p>

 <p className=" font-bold py-2 px-10 md:px-10 lg:px-40 text-2xl md:text-4xl lg:text-4xl text-justify">
         Our Focal Areas
        </p>
        <p className="px-10 font-serif mb-10 lg:px-40  items-center text-md md:text-md lg:text-md text-justify">
  Our CSR agenda is focused on Poverty Alleviation, Women Empowerment and Environ-mental Sustainability through eco-friendly renewable energy.
</p>
         <div className='w-full flex justify-between items-center  md:flex-row flex-col  border-t-[1px] border-t-[#3F3E45]'></div>
          <div className="py-5 px-4 md:px-10 lg:px-40 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 text-black text-justify">
            <div className="text-lg md:text-xl px-10 md:px-20">
          <p className="font-bold text-2xl mb-4">Poverty Allieviation</p>
          </div>
           <div className="text-lg md:text-xl px-10 md:px-20">
          <p className=" mb-4">Support for the alleviation and eradication of extreme poverty and hunger including its attendant effects</p>
          </div>
          </div>

          <div className='w-full flex justify-between items-center  md:flex-row flex-col  border-t-[1px] border-t-[#3F3E45]'></div>
          <div className="py-5 px-4 md:px-10 lg:px-40 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 text-black text-justify">
            <div className="text-lg md:text-xl px-10 md:px-20">
          <p className="font-bold text-2xl mb-4">Economic Empowerment</p>
          </div>
           <div className="text-lg md:text-xl px-10 md:px-20">
          <p className=" mb-4">Helping to create an enabling environment for economic empowerment 
through micro-entrepreneurship, microcredit and skills acquisition for 
women in our operating communities</p>
          </div>

          </div>


          <div className='w-full flex justify-between items-center  md:flex-row flex-col  border-t-[1px] border-t-[#3F3E45]'></div>
          <div className="py-5 px-4 md:px-10 lg:px-40 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 text-black text-justify">
            <div className="text-lg md:text-xl px-10 md:px-20">
          <p className="font-bold text-2xl mb-4">Environmental Sustainability</p>
          </div>
           <div className="text-lg md:text-xl px-10 md:px-20">
          <p className=" mb-4">Commitment towards environmental protection and sustainability</p>
          </div>

          </div>


    <p className=" font-bold py-2 px-10 md:px-10 lg:px-40 text-2xl md:text-4xl lg:text-4xl text-justify">
         Our Work
        </p>
      </div> 
      {/*
     <Community/>
  */}
     <Footer/>
        
     

      </div>
  )
}

export default CommunityDev