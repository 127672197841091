import logo from './logo.svg';
import './App.css';
import Hero from './components/Hero';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OurFarm from './Pages/OurFarm';
import Investors from './Pages/Investors';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Exportzone from './Pages/Exportzone';
import CommunityDev from './Pages/CommunityDev';

function App() {
  return (
  <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
          <Route path="ourfarm" element={<OurFarm/>} />
          <Route path="investors" element={<Investors/>} />
            <Route path="contact" element={<Contact/>} />
               <Route path="about" element={<About/>} />
                 <Route path="exportzone" element={<Exportzone/>} />
                  <Route path="communitydev" element={<CommunityDev/>} />
        </Routes>
        </div>
        </Router>
  );
}

export default App;
