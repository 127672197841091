import React from 'react'

function Timeline() {
  return (
    <div className='h-screen w-full flex justify-center items-center'>
         <div class="relative container mx-auto px-6 flex flex-col space-y-8">
            <div
                class="absolute z-0 w-2 h-full bg-white shadow-md inset-0 left-17 md:mx-auto md:right-0 md:left-0"
            ></div>
            <div class="relative z-10">
               
                <div class="timeline-container">
                    <div class="timeline-pointer" aria-hidden="true"></div>
                    <div class="bg-white p-6 rounded-md shadow-md">
                        <span
                            class="font-bold text-indigo-600 text-sm tracking-wide">1982
                            </span>
                        <h1 class="text-2xl font-bold pt-1">
                            Established Year
                        </h1>
                      {/* <p class="pt-1">
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ex iste suscipit reiciendis, perferendis vel
                            consequuntur cupiditate ad commodi provident,
                            sapiente veniam sed autem.
  </p>*/}
                    </div>
                </div>
            </div>
            <div class="relative z-10">
               
                <div class="timeline-container timeline-container-left">
                    <div
                        class="timeline-pointer timeline-pointer-left"
                        aria-hidden="true"
                    ></div>
                    <div class="bg-white p-6 rounded-md shadow-md">
                        <span
                            class="font-bold text-indigo-600 text-sm tracking-wide"
                            >1989</span
                        >
                        <h1 class="text-2xl font-bold pt-1">Incorporated In</h1>
                        {/* <p class="pt-1">
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ex iste suscipit reiciendis, perferendis vel
                            consequuntur cupiditate ad commodi provident,
                            sapiente veniam sed
                        </p>*/}
                    </div>
                </div>
            </div>
            <div class="relative z-10">
                
                <div class="timeline-container">
                    <div class="timeline-pointer" aria-hidden="true"></div>
                    <div class="bg-white p-6 rounded-md shadow-md">
                        <span
                            class="font-bold text-indigo-600 text-sm tracking-wide"
                            >2001</span
                        >
                        <h1 class="text-2xl font-bold pt-1">Gained EPZ status</h1>
                         {/* <p class="pt-1">
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ex iste suscipit reiciendis, perferendis vel
                            consequuntur cupiditate ad commodi provident,
                            sapiente veniam sed autem, perspiciatis
                        </p>*/}
                    </div>
                </div>
            </div>
             <div class="relative z-10">
                
                <div class="timeline-container">
                    <div class="timeline-pointer" aria-hidden="true"></div>
                    <div class="bg-white p-6 rounded-md shadow-md">
                        <span
                            class="font-bold text-indigo-600 text-sm tracking-wide"
                            >2018</span
                        >
                        <h1 class="text-2xl font-bold pt-1">Became SIFL</h1>
                         {/* <p class="pt-1">
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ex iste suscipit reiciendis, perferendis vel
                            consequuntur cupiditate ad commodi provident,
                            sapiente veniam sed autem, perspiciatis
                        </p>*/}
                    </div>
                </div>
            </div>
        </div>
        </div>
  )
}

export default Timeline