import React from 'react'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom';
import ReachUs from '../components/ReachUs'
import Footer from '../components/Footer'
import bull from '../assets/bull.png'
import exportzone from '../assets/exportzone.JPG'
function Investors() {
    const containerStyle = {
        backgroundImage: `url(${bull})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
    
    };
      const exportZone = {
        backgroundImage: `url(${exportzone})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
    
    };
  return (
    <div>
         <div style={containerStyle}>
        <Navbar />
        <div className="py-40 md:py-60 lg:py-80 text-center">
          <h1 className="font-bold text-4xl font-sans md:text-6xl lg:text-8xl">ALL YOU NEED IS TO INVEST</h1>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto px-10 md:px-8 lg:px-16 py-10 text-black">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="font-bold text-4xl font-sans lg:text-6xl px-4 lg:px-10 py-10 lg:py-60">
            <h1>Partners Portfolio</h1>
          </div>
          <div className="text-justify">
            <p className="text-xl md:text-2xl lg:text-2xl font-bold font-serif py-4">
              Eco-Energy Making energy simpler, cheaper, greener
            </p>
            <p className="px-2 mb-4 font-serif">In a bid to make the eco In a bid to make the eco‐friendly energy choice the easy choice for Nigerians friendly energy choice the easy choice for Nigerians 
                                 and a reliable energy supply than the supply from the eleven(11) Distribution and a reliable energy supply than the supply in Nigeria. SIFL has built an Integrated Renewable Energy plant, 
                                 making the company an Independent Power Producer providing electric power making the company an Independent Power Producer providing electric power 
                                 from Renewable Energy Technologies, RETs, for the benefit of rural and urban from Renewable Energy Technologies.</p>
                  <p className='font-serif  px-2 mb-4'> We all have the power to create green, clean and reliable energy for individuals 
                                 and businesses in Nigeria. Join us for 100% renewable energy supply.  We want 
                                 to make it easier for people and businesses to generate their own electricity. to make it easier for people and businesses to generate their own electricity. </p>

                    <p className='font-serif px-2 mb-4'> Currently, SIFL generates 5MW of electricity produced by our large solar plant 
                                  and then distributes to consumers through our power distribution network (grid). and then distributes to consumers through our power distribution network (grid). 
                                  The electricity is available for sale to off-takers.</p>
                                  </div>        
         </div>  
                     </div>
                      <div className='px-20' style={exportZone}>
                     <div className=" text-m  py-40 md:py-40 lg:py-40 text-center">
          <h1 className="font-bold text-4xl md:text-6xl lg:text-4xl font-sans ">Export Processing Zone</h1>
           <p className='text-2xl py-4 font-bold font-serif'>#1 Trading Zone in North-eastern Nigeria</p>
           <Link to="/exportzone">
  <button className='bg-[#ffb703] font-serif text-white rounded-md text-m lg:text-2xl md:text-6xl font-medium w-[140px] py-4
   ml-4 my-6 px-6 mx-auto md:ml-0'>
    Discover
  </button>
</Link>


        </div>
        
      </div>
               
           
               <div className="text-justify px-10 md:px-40 lg:px-60 xl:px-80">
                

                                  <h2 className='text-2xl py-10 font-sans font-bold'>Free Zone Establishment (FZE)</h2>
                                  <p className='font-serif px-2 mb-4'>FZE registration allows for a single shareholder, shareholders can be an FZE registration allows for a single shareholder, shareholders can be an 
individual/person or a non-individual/company.  An FZE is essentially a Limited 
Liability company within the Export Processing Zone,  meaning that the liabilities 
of the company are limited to its capital paid.  An FZE has a legal personality 
distinct from that of its shareholder and has the capacity, rights and privileges 
of a person</p>
                                  <h2 className='text-2xl py-10 font-sans font-bold'>Free Zone Company (FZCo)</h2>
                                  <p className='font-serif px-2 mb-4'> Shareholders FZCo registration allows for multiple shareholders (up to 50). Shareholders 
can be an individual person or a non individual/company.An FZCo is essentially 
a Limited Liability company within the Export Processing Zone, meaning that a Limited Liability company within the Export Processing Zone, meaning that 
the liabilities of the company are limited to its capital paid. An FZCo has a legal the liabilities of the company are limited to its capital paid. An FZCo has a legal 
personality distinct from that of its shareholder and has the capacity, rights and personality distinct from that of its shareholder and has the capacity,  privileges of a person.</p>
                                    
                                    <h2 className='text-2xl py-10 font-bold font-sans'>Benefits of SIFL EPZ</h2>
                                     <p className=' px-2 mb-4 font-serif'> SIFL offers a range of services specifically designed to meet a variety of export 
processing needs in Nigeria. We provide the following unique services in our EPZ.</p>
         <ul className='list-disc font-serif'>
          <li>Cheap plots of land </li>
          <li>Subsidized State and Local Government Taxes, Rates and Levies.</li>
           <li>Subsidized State and Local Government Taxes, Rates and Levies.</li>
            <li>Duty and tax free importation of raw materials, components, capital goods,</li>
               <li>customer goods and equipment customer goods and equipment</li>
                     <li>Easy access to raw materials •Easy access to raw materials</li>
                           <li>Access to cost effective energy solution •Access to cost effective energy solution</li>
                                 <li>Presence of experienced and highly skilled, semi-skilled and unskilled labour</li>
               <li>Access to basic civil infrastructure and many more</li>
         </ul>
                          

                   <h2 className='text-2xl py-10 font-bold font-sans'>Steps for Registration at Our EPZ</h2>
                   <p className='font-serif'>Here are three simple steps to register your business at SILF EPZ: There are three simple steps to register your business at SILF EPZ:
                        <ul className='font-serif'>
                          <li>
1. Application submission 1. Application submission</li>
<li>2. Registration of Company and Payment 2. Registration of Company and Payment</li>
<li>3. Receive Licence 3. Receive Licence</li>
                        </ul>
                        </p>

                       <h2 className='text-2xl py-10 font-bold'>Documents Required</h2>
                       <ul className='list-disc font-serif mb-4'>
                        <li>Environment Health and Safety (EHS) application form </li>
                        <li>Passport ID copy of manager, directors and secretary </li>
                        <li>Project summary</li>
                        <li>Business profile of shareholder(s)</li>
                        <li> Specimen signature of manager, shareholders, director and
 Secretary. Secretary.</li>
                        <li>Certificate of Registration</li>
                       </ul>
           
                   
                     
             </div> 
               
           <ReachUs/>
                    <Footer/>
    </div>
  )
}

export default Investors