import React from "react";
import { Link } from 'react-router-dom';
import bull from '../assets/bull.png'
import Navbar from "./Navbar";

const Hero = () => {
    const containerStyle = {
        backgroundImage: `url(${bull})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
    };

    return (
        <div>
            <div style={containerStyle}>
                <Navbar />
                <div className="py-40 md:py-80 lg:py-80 px-10 md:px-20 lg:px-40">
                    <h1 className="text-4xl md:text-8xl text-center md:text-center font-extrabold font-poppins">SIFL</h1>
                    <p className=" font-Raleway  text-2xl text-justify md:text-center ">Sebore International farms limited  provides fresh and creamy dairy products with integrated technology 
                        To preserve quality and ensure high standard of cleanliness and hygiene.</p>
                    <button className='bg-[#ffb703] text-white font-Raleway rounded-md font-medium w-[140px] ml-4 my-10 px-10 py-3 mx-auto md:ml-0'>
                        Discover
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Hero;
