import React from 'react';
import person2 from '../assets/person2.png';
import aminu from '../assets/aminu.jpg'

function Partners() {
  return (
    <div className="w-full py-10 md:py-16 lg:py-24 px-6 md:px-8 lg:px-16 xl:px-32 2xl:px-80">
      <div className="items-center py-10 md:py-16">
        <h1 className='text-black font-bold text-6xl text-center'>Our Partners</h1>
      </div>
      <div className='w-full relative font-MuseoModerno px-4  md:px-8 lg:px-12 xl:px-16 2xl:px-20 text-center'>
        <div className='px-4 gap-8 text-black w-full justify-between items-center md:flex-row flex-col grid grid-cols-1 md:grid-cols-2'>
          <div className="text-justify font-serif md:w-[340px] px-4 md:px-10">
            <img className="w-full" src={aminu} alt="In-house Cooking" />
            <p className='item center'>Aminu M. Nyako</p>
            <p className='item center'>Managing Director and CEO</p>
          </div>
          <div className="text-justify  px-6">
            <h1 className='text-black font-bold text-2xl'>Leadership</h1>
            <p className=" py-4  font-serif">
              Aminu Murtala Nyako, Managing Director of Sebore International Farms Limited, is a distinguished economist and public policy expert renowned for his innovative approach to large-scale projects and strategic business development in emerging markets. With a sharp eye for emerging trends, he adeptly navigates complex environments, fostering team collaboration to drive successful growth and deal closures. Throughout his career, Aminu held key positions, including serving as a Special Adviser on Social Development for the Federal Ministry of Humanitarian Affairs, Disaster Management, and Social Development (FMHDSD). Spearheading initiatives to enhance Nigeria’s social protection system, he leverages strategic partnerships with development organisations and the private sector. Having worked with the United States International Labor Organization and Motir Consulting Services in various capacities, Aminu's expertise extends internationally. His commitment to public service is evident through his tenure as Chief of Staff to the Senator representing Adamawa Central Senatorial District in the Nigerian National Assembly. In 2020, Aminu's visionary leadership initiated the Sebore Agro-Industrial Free Trade Zone, integrating smallholder dairy farmers into the Sebore Dairy Factory. Starting with 500 farmers, the program has grown to over 12,000, supported in part by the Bill and Melinda Gates Foundation. Currently, Aminu serves as a Board Member of Nigus Group, further cementing his impact on local and international agricultural landscapes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
