import React from 'react';
import milk from '../assets/milk.jpg';
import yoghurt from '../assets/yoghurt(1).jpg';
import cheese from '../assets/Cheese.jpg';
import butter from '../assets/Butter.JPG';

function Products() {
  return (
    <div className='w-full  items-center px-10 md:px-20 lg:px-40 text-justify bg-[#cbf3f0]'>
      <div className=''>
        <h1 className='font-bold text-black text-center font-sans text-3xl'>Farm Products</h1>
        <p className='text-md items-center font-serif lg:px-20 '>
          We understand the importance of good processing and a good diet to the health, which makes our utmost goal the provision of standard services for the safety and pleasure of families.
        </p>
      </div>
      <div className='flex items-center font-serif overflow-x-auto py-4 md:py-8 lg:py-12 px-4 md:px-8 lg:px-20 xl:px-80'>
        <div className='flex-shrink-0 flex flex-col items-center px-4 text-justify'>
          <img className='w-60 md:w-80 lg:w-68 xl:w-56 my-2' src={milk} alt="/" />
          <div className='text-center'>
            <h1 className='font-bold'>Milk</h1>
          </div>
        </div>

        <div className='flex-shrink-0 flex flex-col items-center px-4 text-justify'>
          <img className='w-60 md:w-79 lg:w-68 xl:w-56 my-2' src={yoghurt} alt="/" />
          <div className='text-center'>
            <h1 className='font-bold'>Yoghurt</h1>
          </div>
        </div>

        <div className='flex-shrink-0 flex flex-col items-center px-4 text-justify'>
          <img className='w-60 md:w-80 lg:w-68 xl:w-56 my-2' src={cheese} alt="/" />
          <div className='text-center'>
            <h1 className='font-bold'>Cheese</h1>
          </div>
        </div>

        <div className='flex-shrink-0 flex flex-col items-center px-4 text-justify'>
          <img className='w-60 md:w-80 lg:w-68 xl:w-56 my-2' src={butter} alt="/" />
          <div className='text-center'>
            <h1 className='font-bold'>Butter</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
