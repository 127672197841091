import React from 'react';
import Navbar from '../components/Navbar';
import dairy from '../assets/Dairy.jpg';
import ReachUs from '../components/ReachUs';
import Footer from '../components/Footer';
import farm from '../assets/farm.jpg';
import borehole from '../assets/BOREHOLE.png'
import empower from '../assets/empower.JPG'
import cost from '../assets/cost.JPG'
import social from '../assets/social.svg'
import milk from '../assets/milk.jpg'
import coldstorage from '../assets/coldstorage.jpg'

function OurFarm() {
  const containerStyle = {
    backgroundImage: `url(${farm})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  };

  return (
    <div>
      <div style={containerStyle}>
        <Navbar />
        <div className="py-60 md:py-80 lg:py-80 ">
          <h1 className="font-bold text-4xl font-sans lg:text-8xl md:text-8xl">INDEGENOUS TECHNOLOGY-DRIVEN FARM</h1>
        </div>
      </div>

      <div className=" px-10   lg:px-20 grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-0 text-black text-justify">
        <div className="font-bold font-sans text-5xl lg:text-6xl md:text-5xl md:px-20  py-10 lg:py-40">
          <h1>Sebore Farm Services</h1>
        </div>
        <div>
          <p className="text-xl font-bold font-serif md:px-40 lg:px-20 py-10 text-justify">Farm technology is constantly evolving, and we keep up with the standard for optimal quality.</p>
          <div className='px-10 font-serif md:px-40 lg:px-20 text-justify  mb-4'>
         <p className=" mb-4">By utilizing the best innovation on our farm, we are able to be more efficient in our work and delivery. State-of-the-art technology is used for milk and dairy production, and the processes involved (ranging from the receipt of raw milk to cooling, pasteurization, and packaging) are automated.</p>
          <p className="mb-4">Our priority is to reduce or eliminate any time and resource wastage, unnecessary cost, and mistakes while attaining the process objective of creating quality products.</p>
          <p className='mb-4'>SIFL’s manufacturing is based on a strict sanitation management system under which inspections are extensively conducted. We proactively maintain and optimize our production and logistic chains. SIFL’s safety and security are supported by this strict system and the company’s employees' high awareness.</p>
      
      </div>
        </div>
        </div>
       
      <div className="px-8 md:px-10 lg:px-10 py-8 lg:gap-10 text-black w-full justify-between gap-8 md:flex-row flex-col grid grid-cols-1 md:grid-cols-4">
        <div>
          <div className="w-full md:w-70 mx-auto mb-4 p-4 md:p-6 max-w-md">
          <img className="w-full h-60" src={cost} alt="Profile" />
        </div>
        
          <h2 className="text-xl font-bold mb-4 font-sans text-[#ffb703]">INCREASING YIELD</h2>
          <p className="text-black font-serif">At SIFL, we have seen an increase between 12% and 40% in milk yields.</p>
        </div>
        <div>
           <div className="w-full md:w-70 mx-auto mb-4 p-4 md:p-6 max-w-md">
          <img className="w-full h-60" src={farm} alt="Profile" />
           </div>
          <h2 className="text-xl font-bold mb-4 font-sans text-[#ffb703]">REDUCING COSTS</h2>
          <p className="text-black font-serif">Our farmers have seen a cost reduction of up to 15% over traditional feeds</p>
        </div>
       
        <div>
           <div className="w-full md:w-70 mx-auto mb-4 p-4 md:p-6 max-w-md">
          <img className="w-full h-60" src={empower} alt="Profile" />
        </div>
          <h2 className="text-xl font-bold mb-4 font-sans text-[#ffb703]">WOMEN EMPOWERMENT</h2>
          <p className="text-black font-serif">As women are the primary owner of cows, extra income feeds in socio-economic benefits.</p>
        </div>
        <div>
           <div className="w-full md:w-70 mx-auto mb-4 p-4 md:p-6 max-w-md">
          <img className="w-full h-60" src={borehole} alt="Profile" />
        </div>
          <h2 className="text-xl font-bold mb-4 font-sans text-[#ffb703]">RENEWABLE ENERGY</h2>
          <p className="text-black font-serif">We are eco-friendly and invest in renewable energy to power SIFL and its environs.</p>
        </div>
      </div>
          <div className="py-5 px-4 md:px-10 lg:px-60 grid grid-cols-1 lg:grid-cols-2 gap-8  text-black text-justify"> 
      <div className='lg:py-10 '>
  
<img className="mx-auto w-[100px] md:w-[50px] lg:w-[50px] my-4" src={social} alt="/" />
<p className="lg:px-40 text-justify font-serif ">
      There is so much to love about dairy. 
Milk, cheese, yoghurt, and, of course, 
butter. However, the real beauty is the 
wealth of scientifically-backed nutritional 
benefits. Check out the facts below to 
learn how dairy can keep your family 
healthy through every stage of life.
  </p>
</div>
       <div >
          <img className="  justify-center md:w-[800px] lg:w-[400px] my-4" src={dairy} alt="/" />
        </div>
    </div>

     <div className="py-5 px-4 md:px-10 lg:px-60 grid grid-cols-1 lg:grid-cols-2 gap-8  text-black text-justify"> 
      <div className='lg:py-10 '>
<img className="mx-auto w-[100px] md:w-[50px] lg:w-[50px] my-4" src={social} alt="/" />
<p className="lg:px-40 text-justify font-serif ">
Our philosophy is self-sufficiency as an organization and for the country in food production. Governments all over the world 
are interested in the potential of value 
chains to develop agriculture and contribute to food security, we are stepping up 
to this demand. SIFL supports the farm 
value chain by producing for our customers feed for various livestock:
  </p>
</div>
 <div >
          <img className="  justify-center md:w-[800px] lg:w-[400px] my-4" src={farm} alt="/" />
        </div>
    </div>
    

   <div className="py-5 px-4 md:px-10 lg:px-60 grid grid-cols-1 lg:grid-cols-2 gap-8  text-black text-justify"> 
      <div className='lg:py-10 '>
<img className="mx-auto w-[100px] md:w-[50px] lg:w-[50px] my-4" src={social} alt="/" />
<p className="lg:px-40 text-justify font-serif ">
  Excellent cow care is an expectation 
here at SIFL. Our cows are cultivated on 
land with an area of approximately 
2000hectares of land in Belwa, the home 
of SIFL. 
We set the highest standards for our 
cows' care when it comes to health, facilities and housing, nutrition, equipment, 
and milking procedures
  </p>
</div>
      <div >
          <img className="  justify-center md:w-[800px] lg:w-[400px] my-4" src={milk} alt="/" />
        </div>
    </div>

        <div className="py-5 px-4 md:px-10 lg:px-60 grid grid-cols-1 lg:grid-cols-2 gap-8  text-black text-justify"> 
      <div className='lg:py-10 '>
<img className="mx-auto w-[100px] md:w-[50px] lg:w-[50px] my-4" src={social} alt="/" />
<p className="lg:px-40 text-justify font-serif">
Our cold storage unit is equipped with 
temperature monitoring technology to 
maintain consistent and adjustable temperature. This is to retain the quality and 
lasting preservation of our products to 
the satisfaction of our consumers. We 
also ensure cleanliness around the facility
  </p>
</div>
<div >
          <img className="  justify-center md:w-[800px] lg:w-[400px] my-4" src={coldstorage} alt="/" />
        </div>
    </div>
  
    
      <ReachUs />
      <Footer />
    </div>
  );
}

export default OurFarm;
