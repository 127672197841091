import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Seborefarmslogov2 from '../assets/Seborefarmslogov2.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 100;
      setNav(!isTop); 
      // Set nav based on scroll position
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMobileNav = () => {
    setMobileNav(!mobileNav);
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling
    });
  };

  return (
    <div className={`font-Raleway fixed top-0 left-0 right-0 z-4 ${nav ? 'bg-white text-black' : 'bg-transparent text-white'}`}>
      <div className="flex justify-between items-center mx-auto px-8 ">
        <ul className="space-x-4 font-bold p-4 flex items-center ml-[-10px]">
          <Link to="/">
            <img className="w-[60px] mx-20 hidden md:block my-4 items-center" src={Seborefarmslogov2} alt="/" />
          </Link>
          <li>
            <Link to="/about" className="p-4 hover:text-[#ffb703] hidden md:block" onClick={scrollToTop}>
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/ourfarm" className="p-4 hover:text-[#ffb703] hidden md:block"  onClick={scrollToTop}>
              OUR FARM
            </Link>
          </li>
          <li>
            <Link to="/investors" className="p-4 hover:text-[#ffb703] hidden md:block" onClick={scrollToTop}>
              INVEST
            </Link>
          </li>
         
          <li>
            <Link to="/communitydev" className="p-4 hover:text-[#ffb703] hidden md:block"  onClick={scrollToTop}>
              SOCIAL IMPACT
            </Link>
          </li>
           <li>
            <Link to="/contact" className="p-4 hover:text-[#ffb703] hidden md:block"  onClick={scrollToTop}>
              CONTACT
            </Link>
          </li>
           

        </ul>
      </div>

      <div onClick={handleMobileNav} className='block md:hidden px-10 text-[#048392]'>
        {mobileNav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      <div
        className={
          mobileNav
            ? 'fixed left-0 top-16 w-full h-full bg-[#ffb703] ease-in-out duration-500'
            : 'hidden'
        }
      >
        <li>
              <Link to="/ourfarm" className="p-4 hover:text-[#000000]" onClick={scrollToTop}>
                OUR FARM
              </Link>
            </li>
            <li>
              <Link to="/investors" className="p-4 hover:text-[#000000]" onClick={scrollToTop}>
                INVEST
              </Link>
            </li>
           
             <li>
              <Link to="/communitydev" className="p-4 hover:text-[#000000]"  onClick={scrollToTop}>
                 SOCIAL IMPACT
              </Link>
            </li>
            <li>
              <Link to="/contact" className="p-4 hover:text-[#000000]"  onClick={scrollToTop}>
                CONTACT
              </Link>
            </li>
           

      </div>
    </div>
  );
};

export default Navbar;
