import React from 'react';
import development from '../assets/development.svg';
import feminism from '../assets/feminism.png';
import medicine from '../assets/medicine.svg';
import money from '../assets/money.png';
import social from '../assets/social.svg';

function Info() {
  return (
    <div className="px-10 md:px-20 lg:px-40 text-justify ">
      <div className='  grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-10 lg:gap-20 text-black' style={{ gridTemplateColumns: '1fr 1.5fr' }}>
        <div className='font-bold text-md md:text-2xl font-sans lg:text-4xl  py-20'>
          <h1>We are Sebore International Farms</h1>
        </div>
        <div>
          <p className='text-md md:text-xl  font-serif py-20 '>SIFL is an integrated sector market leader in West Africa specialised in dairy industry, eco energy, and urban-rural industrialisation.</p>
        </div>
        
      </div>
       <p className='font-serif text-center'>Sebore International Farms is committed to utilising its sectors in the realisation of:</p>
      <div className="flex font-serif flex-col md:flex-row items-center text-center py-10 text-black">
        <ul className="flex flex-wrap justify-around">
          <li className="p-4 w-full md:w-1/2 lg:w-1/3 hover:text-[#000000]">
            <img className="w-[50px] mx-auto my-4" src={development} alt="/" />
            <p>Reconstruction And Development Adamawa And North-east Nigeria</p>
          </li>
          <li className="p-4 w-full md:w-1/2 lg:w-1/3 hover:text-[#000000]">
            <img className="w-[50px] mx-auto my-4" src={feminism} alt="/" />
            <p>Creating Employment Opportunities</p>
          </li>
          <li className="p-4 w-full md:w-1/2 lg:w-1/3 hover:text-[#000000]">
            <img className="w-[50px] mx-auto my-4" src={medicine} alt="/" />
            <p>Sustainable Growth</p>
          </li>
          <li className="p-4 w-full md:w-1/2 lg:w-1/3 hover:text-[#000000]">
            <img className="w-[50px] mx-auto my-4" src={money} alt="/" />
            <p>Capitalization Of Income And Revenue</p>
          </li>
          <li className="p-4 w-full md:w-1/2 lg:w-1/3 hover:text-[#000000]">
            <img className="w-[50px] mx-auto my-4" src={social} alt="/" />
            <p>Transfer Of Know-how And Technology</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Info;
