import React from 'react';
import sebore from '../assets/sebore.png';

function Banner() {
  return (
    <div className='mx-auto'>
      <div className='py-5 px-4 md:px-20 lg:px-40 xl:px-80 grid font-sans grid-cols-1 md:grid-cols-2 gap-8 md:gap-0 text-justify text-black items-center'>
        <p className='text-4xl md:text-6xl px-4 md:px-70 xl:px-80 py-10 font-bold text-center md:text-left'>
         <p className='text-[#22223b]'> Nigeria's </p><p className='text-[#136f63]'>Top </p> <p className='text-[#ffb703]'>Dairy Farmers</p>
          </p>
     
        <div className='text-center md:text-left'>
          <div className='font-bold text-4xl md:text-6xl px-4 md:px-20 py-10'>
            <img className='w-48 md:w-80 lg:w-68 xl:w-56 my-2 mx-auto md:mx-0' src={sebore} alt="/" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
