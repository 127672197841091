import React from 'react'
import exportzone from '../assets/exportzone.JPG'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Exportzone() {
  return (
    <div className='py-10 lg:py-20 md:py-20'>
        <Navbar/>
     <div className='w-full relative text-justify  px-4 sm:px-6 md:px-8 lg:px-10 xl:px-20 2xl:px-40 '>
      <div className='px-4 gap-8 text-black w-full justify-between items-center md:flex-row flex-col grid grid-cols-1 md:grid-cols-2'>
        <div className="text-justify py-10 md:py-20 font-serif">
          
          <img className="w-[400px] md:w-[600px] lg:w-[800px] xl:w-[600px] mx-4 my-4 " src={exportzone} alt="/" />
         
 </div>
 <div>
           <p className=' px-10 py-4 mb-4'>Our privately owned EPZ is set up to improve commercial and industrial exports. Our privately owned EPZ is set up to improve commercial and industrial exports. 
                                  We are a licensed active free zone under the Nigeria Export Processing Zones We are a licensed active free zone under the Nigeria Export Processing Zones 
                                  Authority (NEPZA). Our goal as an Export Processing Zone is to contribute to 
                                  social-economic development by boosting national export growth and social-economic development by boosting national export growth and 
                                  diversification, creation of jobs and income, and promoting technology and skill diversification,  We are on track to becoming a world class free trade zone to 
                                  collaborate with clients across Africa. collaborate with clients across Africa.</p>
         
                   <p className=' px-10 mb-4'>Currently, we offer several formations in our Export Processing Zone that enable
                                   businesses to establish their company to suit exporting requirements. In order businesses to establish their company to suit exporting requirements. In order 
                                   to form a company and establish a business within the zone investor(s) must to form a company and establish a business within the zone, investor(s) must 
                                   choose one of the following formation types</p>
                                  </div>
                                   </div>
                              
                                   </div>
                                        <Footer/>
                                   </div>
                                   
)
}


export default Exportzone