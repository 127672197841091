import React from 'react'
import Hero from './Hero'
import Info from './Info'
import Footer from './Footer'
import ReachUs from './ReachUs'
import Products from './Products'
import Banner from './Banner'
import Community from './Community'

function Home() {
  return (
    <div>
        <Hero/>
        <Info/>
        <Banner/>
        <Products/>
       {/* <Community/>*/}
        <ReachUs/>
        <Footer/>
    </div>
  )
}

export default Home